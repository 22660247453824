import React from 'react'
import PropTypes from 'prop-types'
import { getPage, getOktaUiLabel } from '../adapters/contentful.adapters'
import Layout from '../components/Layout/Layout'
import MetaData from '../components/MetaData/MetaData'
import LoginPageContainer from '../containers/Login/LoginPage'
import LoginPageQuery from '../containers/Login/LoginQuery'
import layoutQuery from '../graphql/queries/layoutQuery'

const LoginPage = ({ layoutData, oktaLabels, page }) => (
  <Layout {...layoutData}>
    <MetaData {...page.pageMetaData} />
    <LoginPageContainer oktaLabels={oktaLabels} {...page} {...layoutData} />
  </Layout>
)

LoginPage.propTypes = {
  layoutData: PropTypes.instanceOf(Object).isRequired,
  oktaLabels: PropTypes.instanceOf(Object).isRequired,
  page: PropTypes.instanceOf(Object).isRequired,
}

export const getStaticProps = async () => {
  const layoutData = await getPage('layoutCollection', layoutQuery)
  const page = await getPage('loginPageCollection', LoginPageQuery)
  const oktaLabels = await getOktaUiLabel()
  return {
    props: {
      layoutData,
      oktaLabels,
      page,
    },
  }
}

export default LoginPage
