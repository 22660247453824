import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import ModalV2 from '../../components/Modal/ModalV2'
import redirectTo, { getParameterByName } from '../../helpers/redirect.helpers'
import Typography from '../../components/Typography/Typography'
import Image from '../../components/Image/Image'
import staticRoutes from '../../../config/router.config'
import loginStore from '../../components/UserManagement/Login-store'
import styles from './LoginPage.tw-styles'
import { formatDateWithHyphen } from '../ReceiptScan/DatePicker/date.helper'
import {
  iohVideoGatingCampaign,
  oktaLogin,
  swifferMopDescription,
} from '../../constants/texts'
import { revtraxDigitalCouponsCampaign } from '../../constants/page-source'
import { oktaAuthentication } from '../../helpers/login.helper'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import Icon from '../../components/Icon/Icon'
import { variants } from '../../constants/variants'
import { standardEventForIneligibilityPopup } from '../AuthContainer/standardEventErrorLog'
import { STATUS_1203 } from '../../constants/status-code'
import { storageAvailable } from '../../adapters/serverside.adapters'

const LoginPageContainer = observer(({
  pageBackground,
  oktaLabels,
  title,
  description,
  promoOutOfStockDescription,
  genGDescription,
  siteSettings,
}) => {
  const [headline, setHeadline] = useState('')
  const [bodyContent, setBodyContent] = useState('')
  const [showIneligiblePromoContent, setShowIneligiblePromoContent] = useState(
    false,
  )
  const router = useRouter()
  const handleGetCampaignDate = (campaignStartAndEndDateCollection, campaignName) => {
    const campaignData = campaignStartAndEndDateCollection.items
      .filter((campaign) => campaign.name === campaignName)
    if (campaignData.length === 0) return false
    const presentDate = new Date(formatDateWithHyphen(new Date()))
    const campaignStartDate = new Date(formatDateWithHyphen(campaignData[0].startDate))
    const campaignEndDate = new Date(formatDateWithHyphen(campaignData[0].endDate))
    if (presentDate >= campaignStartDate && presentDate <= campaignEndDate) {
      return false
    }
    return true
  }

  useEffect(() => {
    const campaignData = siteSettings?.campaignSamplesIdCollection?.items
      .filter((item) => getParameterByName('utm_campaign') === item.name)

    if ((campaignData?.length > 0 && campaignData[0]?.inventoryAvailable !== true)
      || (getParameterByName('utm_campaign') !== null
      && handleGetCampaignDate(siteSettings?.campaignStartAndEndDateCollection, getParameterByName('utm_campaign')))
    ) {
      redirectTo(true, staticRoutes.loginOutOfStock)
      loginStore.outOfStockTraitCampaignName = `oos-${getParameterByName('utm_campaign')}`
      return
    }

    if (getParameterByName('utm_campaign') === 'tapjoy') {
      loginStore.videoGating = true
    } else if (getParameterByName('utm_campaign') === 'video-gating') {
      loginStore.videoGating = true
    } else if (getParameterByName('utm_campaign') === iohVideoGatingCampaign) {
      loginStore.iohVideoGating = true
    } else if (getParameterByName('utm_campaign') === 'downy-unstopables-sample') {
      sessionStorage.setItem('campaignSource', 'downyUnstopablesSampling')
    } else if (getParameterByName('utm_campaign') === 'cascade-platinum-plus-sample') {
      sessionStorage.setItem('campaignSource', 'cascadePlatinumPlusSampling')
    } else if (getParameterByName('utm_campaign') === 'swiffer-powermop-sample') {
      sessionStorage.setItem('campaignSource', 'swifferPowermopSample')
      // eslint-disable-next-line no-undef
      setShowDontHaveAnAccount(true)
    } else if (getParameterByName('utm_campaign') === 'twogeather-music-festival') {
      sessionStorage.setItem('campaignSource', 'twogetherMusicFestival')
      // eslint-disable-next-line no-undef
      setShowDontHaveAnAccount(true)
    } else if (getParameterByName('utm_campaign') === 'cincinnati-music-festival') {
      sessionStorage.setItem('campaignSource', 'cincinnatiMusicFestival')
    }
    switch (storageAvailable('sessionStorage') && typeof sessionStorage !== 'undefined' && sessionStorage.getItem('campaignSource')) {
      case 'purezzzSampling':
        setHeadline('Log in to get your free Vicks PureZzzs Kidz Melatonin Gummies.')
        setBodyContent(description)
        break
      case 'promoOutOfStock':
        setHeadline('Oh snap! We\'re out of stock!')
        setBodyContent(promoOutOfStockDescription)
        break
      case 'genGgiveAway':
        setHeadline('Log in to enter the Astral Clash Sweepstakes')
        setBodyContent(genGDescription)
        break
      case 'twogetherMusicFestival':
        setHeadline('Log in for a free entry to our $1,000 P&G Prize Pack')
        setBodyContent(description)
        break
      case 'musicFestival':
        setHeadline('Log in for a free entry to our Year of P&G Products sweepstakes.')
        setBodyContent(description)
        break
      case 'downyUnstopablesSampling':
        setHeadline('Log in to get your free sample of Downy Unstopables')
        setBodyContent(description)
        break
      case 'cascadePlatinumPlusSampling':
        setHeadline('Log in to get your free sample of Cascade Platinum Plus')
        setBodyContent(description)
        break
      case 'swifferPowermopSample':
        setHeadline('Get your FREE Swiffer PowerMop')
        setBodyContent(swifferMopDescription)
        break
      default:
        setHeadline(title)
        setBodyContent(description)
        break
    }
  }, [router.asPath])

  useEffect(() => {
    switch (loginStore.loginFlowPopup) {
      case 'IneligiblePromoContent':
        setShowIneligiblePromoContent(true)
        standardEventForIneligibilityPopup(STATUS_1203, { })
        break
      default:
        break
    }
    loginStore.loginFlowPopup = null
  }, [loginStore.loginFlowPopup])

  const handleLoginClick = () => {
    const utmCampaignName = getParameterByName('utm_campaign')
    const campaignReroute = revtraxDigitalCouponsCampaign.includes(utmCampaignName)
      ? `login/?utm_campaign=${utmCampaignName}` : ''
    const exactPath = `${process.env.SITE_DOMAIN}/${campaignReroute}`
    oktaAuthentication(oktaLogin, exactPath, utmCampaignName)
  }

  return (
    <div className='bg-bgcolor'>
      <div className={styles.backgroundWrapper}>
        <Image priority={loginStore.priority} className='w-full' {...pageBackground} />
      </div>
      <div className='bg-primary'>
        <h1 aria-level='1' className={styles.content.pageTitle}>LOG IN</h1>
      </div>
      <div className={styles.content.mainWrapper}>
        <div className={styles.content.cascadewrapper}>
          <div className={styles.content.pageSubTitle}>{headline}</div>
          <Typography className={styles.content.subText} markdown={bodyContent} />
          <div className={styles.content.cascadeloginWrapper}>
            {loginStore.userLoggedIn ? (
              <Icon name='spinnerCoral' className='w-24' />
            ) : (
              <SubmitButton
                buttonText={oktaLabels.loginButtonSignInText}
                onClick={handleLoginClick}
              />
            )}
          </div>
        </div>
      </div>
      <style jsx global>
        {`
          [data-cognigy-webchat-root] button[data-cognigy-webchat-toggle],
          #pypestream-wrapper {
            display: none;
          }
          .headWrapper {
            justify-content: center;;
          }
        `}
      </style>
      {showIneligiblePromoContent && (
      <ModalV2
        variant={variants?.paragraphModal?.accountSetupIneligiblePromoContent}
        closeHandler={() => redirectTo(true, staticRoutes.homepage)}
      />
      )}
    </div>

  )
})

LoginPageContainer.propTypes = {
  oktaLabels: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectCause: PropTypes.oneOfType([PropTypes.object]).isRequired,
  confirmationModal: PropTypes.instanceOf(Object).isRequired,
  giftCardPromoTitle: PropTypes.string,
  giftCardPromoDescription: PropTypes.string,
  mlsTitle: PropTypes.string,
  mlsDescription: PropTypes.string,
  successMessageHeading: PropTypes.string,
  successMessageDescription: PropTypes.oneOfType([PropTypes.object]),
  successScreenCta: PropTypes.oneOfType([PropTypes.object]),
  sampleSurveyThankYouBackgroundImage: PropTypes.oneOfType([PropTypes.object]),
  sampleSurveyThankYouScreenCollection: PropTypes.oneOfType([PropTypes.object]),
  sampleScreen4ModalLabels: PropTypes.oneOfType([PropTypes.object]),
  samplingAddressFormBannerImage: PropTypes.oneOfType([PropTypes.object]),
  samplingAddressFormBannerLogo: PropTypes.oneOfType([PropTypes.object]),
  samplingAddressFormTitle: PropTypes.string,
  pureZzzSamplingAddressFormBannerImage: PropTypes.oneOfType([PropTypes.object]),
  pureZzzSamplingAddressFormBannerLogo: PropTypes.oneOfType([PropTypes.object]),
  pureZzzSamplingAddressFormTitle: PropTypes.string,
  pureZzzSampleIdAddToBasketForStage: PropTypes.string,
  pureZzzSampleIdAddToBasketForProd: PropTypes.string,
  sampleScreen2Title: PropTypes.string,
  samplePrivacyText: PropTypes.string,
  pureZzzSamplePrivacyText: PropTypes.string,
  privacyText: PropTypes.string,
  pgeUberDescription: PropTypes.string,
  peptoOutOfStockDescription: PropTypes.string,
  promoOutOfStockDescription: PropTypes.string,
  pgeStarbucksDescription: PropTypes.string,
  genGDescription: PropTypes.string,
  emailVerificationModalContent: PropTypes.oneOfType([PropTypes.object]),
}

LoginPageContainer.defaultProps = {
  giftCardPromoTitle: '',
  giftCardPromoDescription: '',
  mlsTitle: '',
  mlsDescription: '',
  successMessageHeading: '',
  successMessageDescription: {},
  successScreenCta: {},
  sampleSurveyThankYouBackgroundImage: null,
  sampleSurveyThankYouScreenCollection: null,
  sampleScreen4ModalLabels: null,
  samplingAddressFormBannerImage: null,
  samplingAddressFormBannerLogo: null,
  samplingAddressFormTitle: '',
  pureZzzSamplingAddressFormBannerImage: '',
  pureZzzSamplingAddressFormBannerLogo: '',
  pureZzzSamplingAddressFormTitle: '',
  sampleScreen2Title: '',
  samplePrivacyText: '',
  pureZzzSamplePrivacyText: '',
  pureZzzSampleIdAddToBasketForStage: '',
  pureZzzSampleIdAddToBasketForProd: '',
  privacyText: '',
  pgeUberDescription: '',
  pgeStarbucksDescription: '',
  peptoOutOfStockDescription: '',
  promoOutOfStockDescription: '',
  genGDescription: '',
  emailVerificationModalContent: null,
}

export default LoginPageContainer
